import request from "../composables/useHttp"

// 获取工程建设
export function findEngineeringProjects(params) {
    return request("/engineering-project/gw/findEngineeringProjects", {
        params
    })
}
//获取科教文化、资本运作、城市发展
export function findInnovateBusiness(params) {
    return request("/innovate-business/gw/findInnovateBusiness", {
        params
    })
}
export function findGWEngineeringProjectDetails(params) {
    return request("/engineering-project/gw/findGWEngineeringProjectDetails", {
        params
    })
}
//根据id查询工程项目内容详情(门户官网查询使用)
export function findEngineeringProjectDetailById(params) {
    return request("/engineering-project/gw/findEngineeringProjectDetailById", {
        params
    })
}
export function findInnovateBusinessDetailById(params) {
    return request("/innovate-business/gw/findInnovateBusinessDetailById", {
        params
    })
}
